.header {
    padding: 26px 0;
    position: relative;
    z-index: 3;
    .container {
        max-width: 1410px; } }

.h {
    &-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px; }
    &-logo {
        width: 100%;
        max-width: 178px;
        padding: 0 15px; }
    &-menu {
        padding: 0 15px;
        flex: 1;
        ul {
            display: flex;
            justify-content: center;
            li {
                padding: 14px 15px;
                a {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px; } } } }

    &-btn {
        padding: 0 15px; }
    &-mob {
        display: none;
        position: absolute;
        z-index: 2;
        background: $c_white;
        width: 100%;
        top: 100%;
        left: 0; }

    &-btn_menu {
        padding: 0 15px;
        button {
            width: 42px;
            padding: 0;
            background: none;
            border: 0;
            margin-top: 3px;
            span {
                display: block;
                border-radius: 100px;
                height: 4px;
                background: #000;
                transition: 0.3s;
                &:nth-child(2) {
                    margin: 13px 0; } }
            &.active {
                span {
                    transition: 0.3s;
                    &:nth-child(2) {
                        width: 0;
                        transform: translateX(50%); }
                    &:first-child {
                        transform: rotate(45deg) translate(11px, 11px); }
                    &:last-child {
                        transform: rotate(-45deg) translate(12px, -14px); } } } } } }

/*first*/
.first {
    &-wrap {
        border-radius: 10px;
        padding: 31px 25px;
        min-height: 464px;
        -webkit-background-size: cover;
        background-size: cover; }
    &-ttl {
        font-weight: bold;
        font-size: 60px;
        line-height: 73px;
        color: $c_cornflower_blue;
        margin-bottom: 13px; }
    &-txt {
        color: $c_white;
        font-size: 28px;
        line-height: 130%;
        max-width: 900px; } }

/*first*/

/*numbers*/
.numbers {
    padding: 38px 0 14px;
    &-col {
        padding: 0 15px;
        width: 100%;
        max-width: 50%; }
    &-bl {
        margin-bottom: 31px;
        &-ttl {
            font-weight: bold;
            font-size: 60px;
            line-height: 73px;
            margin-bottom: 3px; }
        &-txt {
            font-size: 28px;
            line-height: 34px; } } }


/*numbers*/

/*cats*/
.cats {
    .row {
        margin: 0 -6px; }
    &-col {
        padding: 0 6px;
        margin-bottom: 12px;
        width: 100%;
        max-width: calc(100% / 3); }
    &-bl {
        min-height: 464px;
        width: 100%;
        max-width: 464px;
        position: relative;
        &-ttl {
            font-weight: bold;
            font-size: 42px;
            line-height: 51px;
            color: $c_white;
            margin-bottom: 12px; }
        &-txt {
            font-size: 18px;
            line-height: 130%;
            margin-bottom: 15px;
            color: $c_white; }
        &-btm {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: space-between; }
        &-more {
            padding-right: 15px; }
        &-bl {
            position: relative;
            perspective: 1000px; }
        &-front, &-back {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transition: 1s;
            backface-visibility: hidden;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            -webkit-background-size: cover;
            background-size: cover;
            padding: 34px 40px 40px;
            border-radius: 10px; }
        &-back {
            transform: rotateY(180deg);
            border: 2px solid $c_cornflower_blue; }
        &:hover {
            .cats-bl {
                &-front {
                    transform: rotateY(180deg); }
                &-back {
                    transform: rotateY(360deg); } } }
        &-list {
            li {
                font-size: 18px;
                line-height: 1.2;
                margin-bottom: 15px;
                padding-left: 15px;
                position: relative;
                &:before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    background: $c_cornflower_blue;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    margin-top: -4px;
                    left: 0; } }

            &-ttl {
                font-weight: bold;
                font-size: 28px;
                line-height: 34px;
                margin-bottom: 10px; } } } }
/*cats*/

/*prod*/
.prod {
    &-wrap {
        background: #E7E7E7;
        border-radius: 10px;
        padding: 26px 28px 28px; }
    .container {
        padding: 0 6px; }
    .row {
        margin: 0 -6px; }
    &-ttl {
        margin-bottom: 12px; }
    &-txt {
        margin-bottom: 15px; }
    &-col {
        padding: 0 6px;
        margin-bottom: 12px;
        width: 100%;
        max-width: calc(100% / 3); }
    &-bl {
        &-img {
            margin-bottom: 23px; }
        &-ttl {
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 15px; }
        &-txt {
            font-size: 18px;
            line-height: 130%;
            height: 46px;
            overflow: hidden; }
        &-btm {
            margin-top: 35px;
            display: flex;
            justify-content: space-between; }
        &-more {
            padding-right: 15px; }
        &:hover {
            .prod-bl {
                &-img {
                    svg {
                        path {
                            fill: $c_cornflower_blue; } } }
                &-ttl {
                    color: $c_cornflower_blue;
                    transition: 0.3s; } } } }
    &-bl {
        position: relative;
        perspective: 1000px;
        padding: 40px;
        border-radius: 10px;
        min-height: 421px;
        &-front, &-back {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transition: 1s;
            backface-visibility: hidden;
            background: $c_white;
            display: flex;
            flex-direction: column;
            -webkit-background-size: cover;
            background-size: cover;
            padding: 34px 40px 40px;
            border-radius: 10px; }
        &-back {
            transform: rotateY(180deg); }
        &:hover {
            .prod-bl {
                &-front {
                    transform: rotateY(180deg); }
                &-back {
                    transform: rotateY(360deg); } } }
        &-list {
            li {
                font-size: 18px;
                line-height: 1.2;
                margin-bottom: 15px;
                padding-left: 15px;
                position: relative;
                &:before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    background: $c_cornflower_blue;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    margin-top: -4px;
                    left: 0; } }

            &-ttl {
                font-weight: bold;
                font-size: 28px;
                line-height: 34px;
                margin-bottom: 10px; } } } }

/*prod*/

/*abil*/
.abil {
    padding: 55px 0px 34px;
    &-ttl {
        margin-bottom: 48px; }
    &-col {
        width: 100%;
        max-width: calc(100% / 3);
        padding: 0 15px;
        margin-bottom: 40px; }
    &-bl {
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
    &-txt {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        flex: 1; }
    &-icon {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        border-radius: 50%;
        background:  $c_cornflower_blue; } }

/*abil*/

/*coop*/
.coop {
    padding-bottom: 12px;
    &-wrap {
        background: $c_cornflower_blue;
        border-radius: 10px;
        padding: 26px 28px 28px;
        padding-bottom: 277px; }
    &-ttl {
        margin-bottom: 65px;
        text-align: center;
        color: $c_white; }
    .row {
        margin: 0 -20px; }
    &-col {
        padding: 0 20px;
        width: 100%;
        max-width: calc(100% / 3);
        margin-bottom: 74px; }
    &-bl {
        &-img {
            margin-bottom: 18px; }
        &-ttl {
            color: $c_white;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 24px; }
        &-txt {
            color: $c_white;
            font-size: 18px;
            line-height: 140%; } }
    &-info {
        margin-top: 9px;
        background: $c_white;
        border-radius: 10px;
        padding: 62px 0;
        .container {
            max-width: 970px; }
        &-ttl {
            text-align: center;
            font-weight: bold;
            font-size: 42px;
            line-height: 51px;
            margin-bottom: 22px; }
        &-txt {
            text-align: center;
            font-size: 18px;
            line-height: 130%;
            margin-bottom: 75px; }
        &-slider {
            max-width: 702px;
            margin: 0 auto;
            &_wrap {
                margin-bottom: -277px; } }
        &-slide {
            &-img {
                overflow: hidden;
                border-radius: 10px; } } }
    &-btm {
        margin-top: 31px;
        text-align: center;
        a {
            font-size: 16px;
            line-height: 150%;
            color: $c_white; } } }
/*coop*/

/*saving*/
.saving {
    padding-bottom: 12px;
    &-wrap {
        border-radius: 10px;
        padding: 31px 25px;
        min-height: 464px;
        -webkit-background-size: cover;
        background-size: cover; }
    &-ttl {
        color: $c_cornflower_blue;
        margin-bottom: 13px; }
    &-txt {
        color: $c_white;
        font-size: 28px;
        line-height: 130%;
        max-width: 900px;
        margin-bottom: 72px; }
    &-row {
        display: flex;
        flex-wrap: wrap; }
    &-bl {
        color: $c_white;
        padding-right: 40px;
        &:last-child {
            padding-right: 0; }
        &-ico {
            position: relative;
            svg {
                position: relative;
                z-index: 1; }
            .num {
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                line-height: 130%;
                font-weight: 700;
                padding-top: 15px; } }
        &-txt {
            font-weight: bold;
            font-size: 36px;
            line-height: 130%;
            text-align: center; } } }
/*saving*/

/*qual*/
.qual {
    &-wrap {
        background: $c_cornflower_blue;
        border-radius: 10px;
        padding: 55px 15px; }
    .container {
        max-width: 970px; }
    &-ttl {
        text-align: center;
        color: $c_white;
        margin-bottom: 17px; }
    &-txt {
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        color: $c_white;
        margin-bottom: 75px; }
    &-col {
        padding: 0 15px;
        margin-bottom: 20px;
        width: 100%;
        max-width: calc(100% / 3); }
    &-bl {
        display: block;
        color: $c_white;
        &:hover {
            color: $c_white; }
        &-img {
            display: block;
            text-align: center;
            margin-bottom: 22px; }
        &-ttl {
            font-size: 16px;
            line-height: 130%;
            text-align: center;
            display: block; } } }

/*qual*/

/*revs*/
.revs {
    padding: 62px 0 75px;
    &-ttl {
        text-align: center;
        margin-bottom: 30px; }
    &-tabs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 28px; }
    &-tab {
        margin: 0 6px 12px;
        &.active {
            background: $c_cornflower_blue;
            color: $c_white; } }
    &-wrap {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: -9999;
        &.active {
            pointer-events: all;
            visibility: visible;
            opacity: 1;
            z-index: 0;
            position: static; } }
    &-slider {
        max-width: 702px;
        margin: 0 auto; }
    &-slide {
        &-img {
            margin-bottom: 26px; }
        &-ttl {
            font-size: 24px;
            line-height: 150%;
            text-align: center; }
        &-txt {
            font-size: 13px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-top: 6px;
            color: $c_cornflower_blue; } } }

/*revs*/

/*cf*/
.cf {
    &-wrap {
        background: #E7E7E7;
        border-radius: 10px 10px 0px 0px;
        padding: 55px 25px 80px; }
    .container {
        max-width: 732px;
        margin: 0 auto; }
    &-ttl {
        margin-bottom: 13px;
        text-align: center; }
    &-txt {
        text-align: center;
        font-size: 28px;
        line-height: 130%;
        margin-bottom: 62px; }
    &-form {
        &-input {
            margin-bottom: 26px;
            input {
                background: none;
                width: 100%;
                border-bottom: 1px solid #CDCDCD;
                padding-bottom: 3px;
                font-size: 24px;
                line-height: 150%;
                color: #808080; } }
        &-select {
            margin-bottom: 26px;
            select {
                background: none;
                width: 100%;
                border-bottom: 1px solid #CDCDCD;
                padding-bottom: 3px;
                font-size: 24px;
                line-height: 150%;
                color: #808080;
                background-image: url('../img/select.svg');
                background-position: 100% 50%;
                background-repeat: no-repeat; } }
        &-textarea {
            margin-bottom: 26px;
            textarea {
                background: none;
                width: 100%;
                border-bottom: 1px solid #CDCDCD;
                padding-bottom: 3px;
                font-size: 24px;
                line-height: 150%;
                color: #808080; } }
        &-input {
            &-check {
                margin-bottom: 39px;
                .wpcf7-list-item-label {
                    cursor: pointer;
                    padding-left: 25px;
                    font-size: 14px;
                    display: inline-block;
                    position: relative;
                    letter-spacing: .05em;
                    line-height: 1.5;
                    color: #000;
                    &:before {
                        content: '';
                        display: block;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 3px;
                        left: 0;
                        border: 2px solid #000000;
                        border-radius: 4px; } }
                input:checked + .wpcf7-list-item-label:before {
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 7 1 4' stroke='%2339B54A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                    background-size: 6px 6px;
                    background-position: 2px 3px; } }

            &-btn {
                .btn {
                    border-color: $c_cornflower_blue;
                    background: $c_cornflower_blue;
                    color: $c_white; } } } } }

/*cf*/

/*footer*/
.footer {
    color: $c_white; }

.f {
    &-wrap {
        background: $c_black;
        border-radius: 0px 0px 0px 10px;
        overflow: hidden; }
    &-row {
        display: flex;
        flex-wrap: wrap; }
    &-col {
        width: 50%;
        padding: 26px 40px 40px;
        &.map-wrap {
            padding: 0; } }
    &-ttl {
        color: $c_cornflower_blue; }
    &-txt {
        font-size: 21px;
        line-height: 140%;
        margin-bottom: 27px; }
    &-bl {
        margin-bottom: 27px;
        &-ttl {
            font-size: 15px;
            line-height: 150%;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $c_cornflower_blue;
            margin-bottom: 3px; }

        &-txt {
            font-size: 21px;
            line-height: 140%;
            a {
                display: block; } } }
    &-soc {
        display:  flex;
        flex-wrap: wrap;
        a {
            margin-right: 24px; } } }
/*footer*/

/*modl*/
.modl_window {
    width: calc(100% - 24px);
    max-width: 1366px;
    margin: 0 auto;
    padding: 40px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    overflow: auto;
    max-height: calc(100% - 30px);
    &-wrap {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 999; }
    &-overlay {
        background: #8dc63f;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    &-cls {
        background: none;
        border: 0;
        padding: 0;
        margin-bottom: 33px; }
    &-flex {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center; }
    &-in {
        max-width: 940px;
        margin: 0 auto; }
    &-ttl {
        font-weight: bold;
        font-size: 42px;
        line-height: 51px; }


    &-txt {
        margin-top: 22px;
        font-size: 18px;
        line-height: 150%; }


    &-img {
        margin-top: 53px;
        text-align: center; } }

/*modl*/
