/*header*/
@media (min-width: 1200px) {
  .h {
    &-btn_menu {
      display: none; }
    &-mob {
      display: none !important; } } }
@media (min-width: 1200px) and (max-width: 1399px) {
  .h {
    &-menu {
      ul {
        li {
          padding: 14px 10px;
          a {
            font-size: 14px; } } } } } }

@media (max-width: 1199px) {
  .h-menu {
    display: none;
    ul {
      li {
        a {
          font-size: 28px;
          line-height: 34px; } } } }
  .h {
    &-logo {
      max-width: none;
      flex: 1; }
    &-mob {
      padding-bottom: 26px;
      .h-btn {
        display: none; }
      .h-menu {
        display: block;
        ul {
          flex-direction: column;
          align-items: flex-end; } } } } }

@media (max-width: 639px) {
  .h {
    &-menu {
      ul {
        li {
          a {
            font-size: 21px;
            line-height: 25px; } } } }
    &-btn {
      display: none; }
    &-mob {
      .h-btn {
        display: block;
        text-align: right; } } } }

/*header*/

/*first*/
@media (max-width: 1199px) {
  .first {
    &-ttl {
      font-size: 48px;
      line-height: 100%; }
    &-txt {
      font-size: 28px;
      line-height: 130%; } } }

@media (max-width: 959px) {
  .first {
    &-wrap {
      min-height: 411px; }
    &-ttl {
      font-size: 42px; }
    &-txt {
      font-size: 21px; } } }

@media (max-width: 639px) {
  .first {
    &-wrap {
      padding: 16px 5px 20px; }
    &-ttl {
      font-size: 28px; }
    &-txt {
      font-size: 18px; }
    &-wrap {
      min-height: 220px; } } }
/*first*/

/*numbers*/
@media (max-width: 1199px) {
  .numbers {
    &-bl {
      &-ttl {
        font-size: 48px;
        line-height: 100%; }
      &-txt {
        font-size: 28px;
        line-height: 34px; } } } }

@media (max-width: 959px) {
  .numbers {
    &-col {
      max-width: 100%; }
    &-bl {
      &-ttl {
        font-size: 42px; }
      &-txt {
        font-size: 21px; } } } }

@media (max-width: 639px) {
  .numbers {
    &-bl {
      &-ttl {
        font-size: 28px; }
      &-txt {
        font-size: 18px;
        line-height: 130%; } } } }

/*numbers*/

/*cats*/
@media (max-width: 1199px) {
  .cats {
    &-col {
      max-width: 100%; }
    &-bl {
      max-width: 874px;
      margin: 0 auto;
      &-ttl {
        font-size: 42px;
        line-height: 51px; } } } }

@media (max-width: 959px) {
  .cats {
    &-bl {
      padding: 21px 30px 30px;
      &-ttl {
        font-size: 36px;
        line-height: 44px; }
      &-txt {
        font-size: 18px; } } } }

@media (max-width: 639px) {
  .cats {
    &-bl {
      padding:  13px 20px 20px;
      &-ttl {
        font-size: 28px;
        line-height: 34px; }
      &-btm {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start; }
      &-more {
        margin-bottom: 20px; }
      &-back, &-front {
        background-position: center center; }
      &:hover {
        .cats-bl {
          &-front {
            transform: rotateY(0deg); }
          &-back {
            transform: rotateY(180deg); } } }
      &.reverse {
        .cats-bl {
          &-front {
            transform: rotateY(180deg); }
          &-back {
            transform: rotateY(360deg); } } } } } }

/*cats*/

/*prod*/
@media (max-width: 1199px) {
  .prod {
    &-col {
      max-width: 50%; } } }

@media (max-width: 959px) {
  .prod {
    &-wrap {
      padding-left: 15px;
      padding-right: 15px; }
    &-col {
      max-width: 100%; } } }

@media (max-width: 639px) {
  .prod {
    &-bl {
      padding: 20px;
      &-ttl {
        font-size: 24px; }
      &-txt {
        height: 47px; }
      &-btm {
        flex-direction: column;
        margin-top: 20px; }
      &-more {
        margin-bottom: 20px; }
      &:hover {
        .prod-bl {
          &-front {
            transform: rotateY(0deg); }
          &-back {
            transform: rotateY(180deg); } } }
      &.reverse {
        .prod-bl {
          &-front {
            transform: rotateY(180deg); }
          &-back {
            transform: rotateY(360deg); } } } } } }
/*prod*/

/*abil*/
@media (max-width: 1199px) {
  .abil {
    &-txt {
      font-size: 18px;
      line-height: 22px; } } }

@media (max-width: 959px) {
  .abil {
    &-col {
      max-width: 100%; } } }

/*abil*/

/*coop*/
@media (max-width: 1199px) {
  .coop {
    &-col {
      max-width: 50%; }
    &-info {
      &-ttl {
        font-size: 36px;
        line-height: 100%; }
      &-txt {
        font-size: 18px;
        line-height: 130%; } } } }

@media (max-width: 959px) {
  .coop {
    &-ttl {
      text-align: left; }
    &-col {
      max-width: 100%; }
    &-wrap {
      padding-left: 15px;
      padding-right: 15px; }
    &-info {
      &-slider {
        max-width: 424px; } } } }


@media (max-width: 639px) {
  .coop {
    &-ttl {
      margin-bottom: 28px; }
    &-bl {
      &-ttl {
        font-size: 24px;
        line-height: 29px; } }
    &-wrap {
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 165px; }
    &-col {
      margin-bottom: 40px; }
    &-info {
      margin: 0 -20px;
      &-txt {
        margin-bottom: 45px; }
      &-slider {
        padding-top: 60px; } } } }

/*coop*/

/*saving*/
@media (max-width: 1199px) {
  .saving {} }

@media (max-width: 959px) {
  .saving {
    &-txt {
      font-size: 21px; } } }

@media (max-width: 639px) {
  .saving {
    &-wrap {
      padding: 17px 5px; }
    &-txt {
      margin-bottom: 28px; }
    &-row {
      width: 100%;
      align-items: center;
      flex-direction: column; }
    &-bl {
      padding-right: 0; } } }

/*saving*/

/*qual*/
@media (max-width: 1199px) {
  .qual {} }

@media (max-width: 959px) {
  .qual {
    .row {
      flex-direction: column;
      align-items: center; }
    &-col {
      max-width: 100%; } } }

@media (max-width: 639px) {
  .qual {
    &-wrap {
      padding: 28px 17px; }
    &-ttl {
      text-align: left; }
    &-txt {
      text-align: left;
      margin-bottom: 37px; }
    &-bl {
      &-img {
        text-align: left; }
      &-ttl {
        text-align: left; } }
    &-col {
      margin-bottom: 35px; } } }

/*qual*/

/*revs*/
@media (max-width: 1199px) {
  .revs {} }

@media (max-width: 959px) {
  .revs {
    &-slider {
      max-width: 448px; }
    &-slide {
      &-ttl {
        font-size: 24px;
        line-height: 115%; }
      &-txt {
        font-size: 13px;
        line-height: 130%; } } } }

@media (max-width: 639px) {
  .revs {
    padding-bottom: 34px;
    &-slider {
      padding: 60px 15px 10px;
      .slick-arrow {
        &.arrow-prev {
          left: 15px; }
        &.arrow-next {
          right: 15px; } } }
    &-slide {
      &-ttl {
        font-size: 18px;
        text-align: left; }
      &-txt {
        text-align: left; } } } }

/*revs*/

/*cf*/
@media (max-width: 1199px) {
  .cf {} }

@media (max-width: 959px) {
  .cf {
    &-wrap {
      padding-left: 15px;
      padding-right: 15px; }
    &-ttl {
      text-align: left; }
    &-txt {
      text-align: left; }
    &-txt {
      font-size: 21px; } } }

@media (max-width: 959px) {
  .cf {
    &-wrap {
      padding-top: 28px; }
    &-txt {
      margin-bottom: 30px;
      font-size: 18px; } } }
/*cf*/

/*footer*/
@media (max-width: 1199px) {
  .f {
    &-row {
      flex-direction: column-reverse; }
    &-col {
      width: 100%; } } }

@media (max-width: 639px) {
  .f {
    &-col {
      padding: 26px 30px 30px; } } }
/*footer*/

/*modl*/
@media (max-width: 1199px) {
  .modl_window {
    &-ttl {
      font-size: 36px;
      line-height: 44px; } } }
@media (max-width: 959px) {
  .modl_window {
    &-ttl {
      font-size: 36px;
      line-height: 44px; } } }
@media (max-width: 639px) {
  .modl_window {
    padding: 20px;
    &-cls {
      margin-bottom: 21px; }
    &-ttl {
      font-size: 28px;
      line-height: 100%; }
    &-txt {
      font-size: 16px;
      line-height: 150%;
      margin-top: 17px; }
    &-img {
      margin-top: 24px; } } }
/*modl*/
