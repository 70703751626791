html,
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $c_black;
    font-weight: 300;
    line-height: 1;
    font-family: $f_Inter; }

html {
    overflow: hidden;
    overflow-y: auto; }

$placeholderSelectors: '::-webkit-input-placeholder' '::-moz-placeholder' ':-ms-input-placeholder' ':-moz-placeholder';
@each $selector in $placeholderSelectors {
    *#{$selector} {
        @include transition(0.3s); } }

* {
    outline: 0; }

main {
    flex-grow: 1; }

svg, svg path, a, button {
    @include transition(0.3s); }

a {
    &:hover {
        color: $c_cornflower_blue; } }

a:visited {
    color: inherit; }


img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto; }

.h2 {
    font-weight: bold;
    font-size: 60px;
    line-height: 73px; }

@media (max-width: 1199px) {
    .h2 {
        font-size: 48px;
        line-height: 100%; } }
@media (max-width: 959px) {
    .h2 {
        font-size: 42px; } }
@media (max-width: 639px) {
    .h2 {
        font-size: 28px;
        line-height: 115%; } }


.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1366px;
    position: relative;
    z-index: 2; }

@media (min-width: 960px) and (max-width: 1199px) {
    .container {
        max-width: 886px; } }

@media (max-width: 959px) {
    .container {
        max-width: 100%; } }

.container-wrap {
    width: calc(100% - 24px);
    margin: 0 auto;
    max-width: 1416px;
    position: relative;
    z-index: 2;
    @media (max-width: 639px) {
        width: 100%; } }
.row {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap; }

.btn {
    text-decoration: none;
    background: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border: 2px solid $c_cornflower_blue;
    border-radius: 100px;
    padding: 12px 18px 11px;
    display: inline-block;
    cursor: pointer;
    &.white {
        border-color: $c_white;
        color: $c_white; }
    &:hover {
        color: $c_white;
        background: $c_cornflower_blue;
        border-color: $c_cornflower_blue; } }


.btn-plus {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    .plus {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        border-radius: 50%;
        border: 2px solid $c_cornflower_blue; }
    &.white {
        color: $c_white;
        .plus {
            background: $c_cornflower_blue;
            svg {
                rect {
                    fill: $c_white; } } } } }


.slick-arrow {
    position: absolute;
    z-index: 3;
    display: inline-block;
    top: 163px;
    cursor: pointer;
    &.arrow-prev {
        left: -119px;
        z-index: 3;
        display: inline-block; }
    &.arrow-next {
        right: -119px; } }

@media (max-width: 1199px) {
    .slick-arrow {
        &.arrow-prev {
            left: -70px; }
        &.arrow-next {
            right: -70px; } } }
@media (max-width: 959px) {
    .slick-arrow {
        top: 100px;
        width: 24px;
        svg {
            width: 24px;
            height: 42px; }
        &.arrow-prev {
            left: -60px; }
        &.arrow-next {
            right: -60px; } } }
@media (max-width: 639px) {
    .slick-arrow {
        top: 0;
        &.arrow-prev {
            left: 0; }
        &.arrow-next {
            right: 0; } } }

.hidden {
    display: none; }
