/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  vertical-align: baseline;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Custom */
*, *:after, *:before {
  box-sizing: border-box; }

input, textarea, select {
  border: none;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

a, input, textarea, button, select,
a:focus, input:focus, textarea:focus, button:focus, select:focus,
a:active, input:active, textarea:active, button:active, select:active {
  outline: none; }

textarea {
  resize: none; }

img {
  display: block;
  max-width: 100%;
  border-style: none; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }
  aactive {
    color: inherit; }

[hidden] {
  display: none; }

/*Inter-Regular*/
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Regular/Inter-Regular.eot"), url("../fonts/Inter-Regular/Inter-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-Regular/Inter-Regular.svg"), url("../fonts/Inter-Regular/Inter-Regular.woff") format("woff"), url("../fonts/Inter-Regular/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*Inter-Regular*/
/*Inter-Medium*/
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Medium/Inter-Medium.eot"), url("../fonts/Inter-Medium/Inter-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-Medium/Inter-Medium.svg"), url("../fonts/Inter-Medium/Inter-Medium.woff") format("woff"), url("../fonts/Inter-Medium/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

/*Inter-Medium*/
/*Inter-Bold*/
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Bold/Inter-Bold.eot"), url("../fonts/Inter-Bold/Inter-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-Bold/Inter-Bold.svg"), url("../fonts/Inter-Bold/Inter-Bold.woff") format("woff"), url("../fonts/Inter-Bold/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/*Inter-Bold*/
/*Inter-SemiBold*/
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-SemiBold/Inter-SemiBold.eot"), url("../fonts/Inter-SemiBold/Inter-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-SemiBold/Inter-SemiBold.svg"), url("../fonts/Inter-SemiBold/Inter-SemiBold.woff") format("woff"), url("../fonts/Inter-SemiBold/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

/*Inter-SemiBold*/
/*Inter-Light*/
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Light/Inter-Light.eot"), url("../fonts/Inter-Light/Inter-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Inter-Light/Inter-Light.svg"), url("../fonts/Inter-Light/Inter-Light.woff") format("woff"), url("../fonts/Inter-Light/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

/*Inter-Light*/
html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #000000;
  font-weight: 300;
  line-height: 1;
  font-family: "Inter", sans-serif; }

html {
  overflow: hidden;
  overflow-y: auto; }

*::-webkit-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*::-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-ms-input-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

*:-moz-placeholder {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

* {
  outline: 0; }

main {
  flex-grow: 1; }

svg, svg path, a, button {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

a:hover {
  color: #8DC63F; }

a:visited {
  color: inherit; }

img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto; }

.h2 {
  font-weight: bold;
  font-size: 60px;
  line-height: 73px; }

@media (max-width: 1199px) {
  .h2 {
    font-size: 48px;
    line-height: 100%; } }

@media (max-width: 959px) {
  .h2 {
    font-size: 42px; } }

@media (max-width: 639px) {
  .h2 {
    font-size: 28px;
    line-height: 115%; } }

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1366px;
  position: relative;
  z-index: 2; }

@media (min-width: 960px) and (max-width: 1199px) {
  .container {
    max-width: 886px; } }

@media (max-width: 959px) {
  .container {
    max-width: 100%; } }

.container-wrap {
  width: calc(100% - 24px);
  margin: 0 auto;
  max-width: 1416px;
  position: relative;
  z-index: 2; }
  @media (max-width: 639px) {
    .container-wrap {
      width: 100%; } }

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap; }

.btn {
  text-decoration: none;
  background: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border: 2px solid #8DC63F;
  border-radius: 100px;
  padding: 12px 18px 11px;
  display: inline-block;
  cursor: pointer; }
  .btn.white {
    border-color: #FFFFFF;
    color: #FFFFFF; }
  .btn:hover {
    color: #FFFFFF;
    background: #8DC63F;
    border-color: #8DC63F; }

.btn-plus {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px; }
  .btn-plus .plus {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 50%;
    border: 2px solid #8DC63F; }
  .btn-plus.white {
    color: #FFFFFF; }
    .btn-plus.white .plus {
      background: #8DC63F; }
      .btn-plus.white .plus svg rect {
        fill: #FFFFFF; }

.slick-arrow {
  position: absolute;
  z-index: 3;
  display: inline-block;
  top: 163px;
  cursor: pointer; }
  .slick-arrow.arrow-prev {
    left: -119px;
    z-index: 3;
    display: inline-block; }
  .slick-arrow.arrow-next {
    right: -119px; }

@media (max-width: 1199px) {
  .slick-arrow.arrow-prev {
    left: -70px; }
  .slick-arrow.arrow-next {
    right: -70px; } }

@media (max-width: 959px) {
  .slick-arrow {
    top: 100px;
    width: 24px; }
    .slick-arrow svg {
      width: 24px;
      height: 42px; }
    .slick-arrow.arrow-prev {
      left: -60px; }
    .slick-arrow.arrow-next {
      right: -60px; } }

@media (max-width: 639px) {
  .slick-arrow {
    top: 0; }
    .slick-arrow.arrow-prev {
      left: 0; }
    .slick-arrow.arrow-next {
      right: 0; } }

.hidden {
  display: none; }

.header {
  padding: 26px 0;
  position: relative;
  z-index: 3; }
  .header .container {
    max-width: 1410px; }

.h-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.h-logo {
  width: 100%;
  max-width: 178px;
  padding: 0 15px; }

.h-menu {
  padding: 0 15px;
  flex: 1; }
  .h-menu ul {
    display: flex;
    justify-content: center; }
    .h-menu ul li {
      padding: 14px 15px; }
      .h-menu ul li a {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px; }

.h-btn {
  padding: 0 15px; }

.h-mob {
  display: none;
  position: absolute;
  z-index: 2;
  background: #FFFFFF;
  width: 100%;
  top: 100%;
  left: 0; }

.h-btn_menu {
  padding: 0 15px; }
  .h-btn_menu button {
    width: 42px;
    padding: 0;
    background: none;
    border: 0;
    margin-top: 3px; }
    .h-btn_menu button span {
      display: block;
      border-radius: 100px;
      height: 4px;
      background: #000;
      transition: 0.3s; }
      .h-btn_menu button span:nth-child(2) {
        margin: 13px 0; }
    .h-btn_menu button.active span {
      transition: 0.3s; }
      .h-btn_menu button.active span:nth-child(2) {
        width: 0;
        transform: translateX(50%); }
      .h-btn_menu button.active span:first-child {
        transform: rotate(45deg) translate(11px, 11px); }
      .h-btn_menu button.active span:last-child {
        transform: rotate(-45deg) translate(12px, -14px); }

/*first*/
.first-wrap {
  border-radius: 10px;
  padding: 31px 25px;
  min-height: 464px;
  -webkit-background-size: cover;
  background-size: cover; }

.first-ttl {
  font-weight: bold;
  font-size: 60px;
  line-height: 73px;
  color: #8DC63F;
  margin-bottom: 13px; }

.first-txt {
  color: #FFFFFF;
  font-size: 28px;
  line-height: 130%;
  max-width: 900px; }

/*first*/
/*numbers*/
.numbers {
  padding: 38px 0 14px; }
  .numbers-col {
    padding: 0 15px;
    width: 100%;
    max-width: 50%; }
  .numbers-bl {
    margin-bottom: 31px; }
    .numbers-bl-ttl {
      font-weight: bold;
      font-size: 60px;
      line-height: 73px;
      margin-bottom: 3px; }
    .numbers-bl-txt {
      font-size: 28px;
      line-height: 34px; }

/*numbers*/
/*cats*/
.cats .row {
  margin: 0 -6px; }

.cats-col {
  padding: 0 6px;
  margin-bottom: 12px;
  width: 100%;
  max-width: calc(100% / 3); }

.cats-bl {
  min-height: 464px;
  width: 100%;
  max-width: 464px;
  position: relative; }
  .cats-bl-ttl {
    font-weight: bold;
    font-size: 42px;
    line-height: 51px;
    color: #FFFFFF;
    margin-bottom: 12px; }
  .cats-bl-txt {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 15px;
    color: #FFFFFF; }
  .cats-bl-btm {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  .cats-bl-more {
    padding-right: 15px; }
  .cats-bl-bl {
    position: relative;
    perspective: 1000px; }
  .cats-bl-front, .cats-bl-back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 1s;
    backface-visibility: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 34px 40px 40px;
    border-radius: 10px; }
  .cats-bl-back {
    transform: rotateY(180deg);
    border: 2px solid #8DC63F; }
  .cats-bl:hover .cats-bl-front {
    transform: rotateY(180deg); }
  .cats-bl:hover .cats-bl-back {
    transform: rotateY(360deg); }
  .cats-bl-list li {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 15px;
    padding-left: 15px;
    position: relative; }
    .cats-bl-list li:before {
      content: '';
      width: 8px;
      height: 8px;
      background: #8DC63F;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: 0; }
  .cats-bl-list-ttl {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 10px; }

/*cats*/
/*prod*/
.prod-wrap {
  background: #E7E7E7;
  border-radius: 10px;
  padding: 26px 28px 28px; }

.prod .container {
  padding: 0 6px; }

.prod .row {
  margin: 0 -6px; }

.prod-ttl {
  margin-bottom: 12px; }

.prod-txt {
  margin-bottom: 15px; }

.prod-col {
  padding: 0 6px;
  margin-bottom: 12px;
  width: 100%;
  max-width: calc(100% / 3); }

.prod-bl-img {
  margin-bottom: 23px; }

.prod-bl-ttl {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 15px; }

.prod-bl-txt {
  font-size: 18px;
  line-height: 130%;
  height: 46px;
  overflow: hidden; }

.prod-bl-btm {
  margin-top: 35px;
  display: flex;
  justify-content: space-between; }

.prod-bl-more {
  padding-right: 15px; }

.prod-bl:hover .prod-bl-img svg path {
  fill: #8DC63F; }

.prod-bl:hover .prod-bl-ttl {
  color: #8DC63F;
  transition: 0.3s; }

.prod-bl {
  position: relative;
  perspective: 1000px;
  padding: 40px;
  border-radius: 10px;
  min-height: 421px; }
  .prod-bl-front, .prod-bl-back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 1s;
    backface-visibility: hidden;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 34px 40px 40px;
    border-radius: 10px; }
  .prod-bl-back {
    transform: rotateY(180deg); }
  .prod-bl:hover .prod-bl-front {
    transform: rotateY(180deg); }
  .prod-bl:hover .prod-bl-back {
    transform: rotateY(360deg); }
  .prod-bl-list li {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 15px;
    padding-left: 15px;
    position: relative; }
    .prod-bl-list li:before {
      content: '';
      width: 8px;
      height: 8px;
      background: #8DC63F;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: 0; }
  .prod-bl-list-ttl {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 10px; }

/*prod*/
/*abil*/
.abil {
  padding: 55px 0px 34px; }
  .abil-ttl {
    margin-bottom: 48px; }
  .abil-col {
    width: 100%;
    max-width: calc(100% / 3);
    padding: 0 15px;
    margin-bottom: 40px; }
  .abil-bl {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .abil-txt {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    flex: 1; }
  .abil-icon {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border-radius: 50%;
    background: #8DC63F; }

/*abil*/
/*coop*/
.coop {
  padding-bottom: 12px; }
  .coop-wrap {
    background: #8DC63F;
    border-radius: 10px;
    padding: 26px 28px 28px;
    padding-bottom: 277px; }
  .coop-ttl {
    margin-bottom: 65px;
    text-align: center;
    color: #FFFFFF; }
  .coop .row {
    margin: 0 -20px; }
  .coop-col {
    padding: 0 20px;
    width: 100%;
    max-width: calc(100% / 3);
    margin-bottom: 74px; }
  .coop-bl-img {
    margin-bottom: 18px; }
  .coop-bl-ttl {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 24px; }
  .coop-bl-txt {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 140%; }
  .coop-info {
    margin-top: 9px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 62px 0; }
    .coop-info .container {
      max-width: 970px; }
    .coop-info-ttl {
      text-align: center;
      font-weight: bold;
      font-size: 42px;
      line-height: 51px;
      margin-bottom: 22px; }
    .coop-info-txt {
      text-align: center;
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 75px; }
    .coop-info-slider {
      max-width: 702px;
      margin: 0 auto; }
      .coop-info-slider_wrap {
        margin-bottom: -277px; }
    .coop-info-slide-img {
      overflow: hidden;
      border-radius: 10px; }
  .coop-btm {
    margin-top: 31px;
    text-align: center; }
    .coop-btm a {
      font-size: 16px;
      line-height: 150%;
      color: #FFFFFF; }

/*coop*/
/*saving*/
.saving {
  padding-bottom: 12px; }
  .saving-wrap {
    border-radius: 10px;
    padding: 31px 25px;
    min-height: 464px;
    -webkit-background-size: cover;
    background-size: cover; }
  .saving-ttl {
    color: #8DC63F;
    margin-bottom: 13px; }
  .saving-txt {
    color: #FFFFFF;
    font-size: 28px;
    line-height: 130%;
    max-width: 900px;
    margin-bottom: 72px; }
  .saving-row {
    display: flex;
    flex-wrap: wrap; }
  .saving-bl {
    color: #FFFFFF;
    padding-right: 40px; }
    .saving-bl:last-child {
      padding-right: 0; }
    .saving-bl-ico {
      position: relative; }
      .saving-bl-ico svg {
        position: relative;
        z-index: 1; }
      .saving-bl-ico .num {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        line-height: 130%;
        font-weight: 700;
        padding-top: 15px; }
    .saving-bl-txt {
      font-weight: bold;
      font-size: 36px;
      line-height: 130%;
      text-align: center; }

/*saving*/
/*qual*/
.qual-wrap {
  background: #8DC63F;
  border-radius: 10px;
  padding: 55px 15px; }

.qual .container {
  max-width: 970px; }

.qual-ttl {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 17px; }

.qual-txt {
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 75px; }

.qual-col {
  padding: 0 15px;
  margin-bottom: 20px;
  width: 100%;
  max-width: calc(100% / 3); }

.qual-bl {
  display: block;
  color: #FFFFFF; }
  .qual-bl:hover {
    color: #FFFFFF; }
  .qual-bl-img {
    display: block;
    text-align: center;
    margin-bottom: 22px; }
  .qual-bl-ttl {
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    display: block; }

/*qual*/
/*revs*/
.revs {
  padding: 62px 0 75px; }
  .revs-ttl {
    text-align: center;
    margin-bottom: 30px; }
  .revs-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 28px; }
  .revs-tab {
    margin: 0 6px 12px; }
    .revs-tab.active {
      background: #8DC63F;
      color: #FFFFFF; }
  .revs-wrap {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -9999; }
    .revs-wrap.active {
      pointer-events: all;
      visibility: visible;
      opacity: 1;
      z-index: 0;
      position: static; }
  .revs-slider {
    max-width: 702px;
    margin: 0 auto; }
  .revs-slide-img {
    margin-bottom: 26px; }
  .revs-slide-ttl {
    font-size: 24px;
    line-height: 150%;
    text-align: center; }
  .revs-slide-txt {
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 6px;
    color: #8DC63F; }

/*revs*/
/*cf*/
.cf-wrap {
  background: #E7E7E7;
  border-radius: 10px 10px 0px 0px;
  padding: 55px 25px 80px; }

.cf .container {
  max-width: 732px;
  margin: 0 auto; }

.cf-ttl {
  margin-bottom: 13px;
  text-align: center; }

.cf-txt {
  text-align: center;
  font-size: 28px;
  line-height: 130%;
  margin-bottom: 62px; }

.cf-form-input {
  margin-bottom: 26px; }
  .cf-form-input input {
    background: none;
    width: 100%;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 3px;
    font-size: 24px;
    line-height: 150%;
    color: #808080; }

.cf-form-select {
  margin-bottom: 26px; }
  .cf-form-select select {
    background: none;
    width: 100%;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 3px;
    font-size: 24px;
    line-height: 150%;
    color: #808080;
    background-image: url("../img/select.svg");
    background-position: 100% 50%;
    background-repeat: no-repeat; }

.cf-form-textarea {
  margin-bottom: 26px; }
  .cf-form-textarea textarea {
    background: none;
    width: 100%;
    border-bottom: 1px solid #CDCDCD;
    padding-bottom: 3px;
    font-size: 24px;
    line-height: 150%;
    color: #808080; }

.cf-form-input-check {
  margin-bottom: 39px; }
  .cf-form-input-check .wpcf7-list-item-label {
    cursor: pointer;
    padding-left: 25px;
    font-size: 14px;
    display: inline-block;
    position: relative;
    letter-spacing: .05em;
    line-height: 1.5;
    color: #000; }
    .cf-form-input-check .wpcf7-list-item-label:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 3px;
      left: 0;
      border: 2px solid #000000;
      border-radius: 4px; }
  .cf-form-input-check input:checked + .wpcf7-list-item-label:before {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1L3 7 1 4' stroke='%2339B54A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: 6px 6px;
    background-position: 2px 3px; }

.cf-form-input-btn .btn {
  border-color: #8DC63F;
  background: #8DC63F;
  color: #FFFFFF; }

/*cf*/
/*footer*/
.footer {
  color: #FFFFFF; }

.f-wrap {
  background: #000000;
  border-radius: 0px 0px 0px 10px;
  overflow: hidden; }

.f-row {
  display: flex;
  flex-wrap: wrap; }

.f-col {
  width: 50%;
  padding: 26px 40px 40px; }
  .f-col.map-wrap {
    padding: 0; }

.f-ttl {
  color: #8DC63F; }

.f-txt {
  font-size: 21px;
  line-height: 140%;
  margin-bottom: 27px; }

.f-bl {
  margin-bottom: 27px; }
  .f-bl-ttl {
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #8DC63F;
    margin-bottom: 3px; }
  .f-bl-txt {
    font-size: 21px;
    line-height: 140%; }
    .f-bl-txt a {
      display: block; }

.f-soc {
  display: flex;
  flex-wrap: wrap; }
  .f-soc a {
    margin-right: 24px; }

/*footer*/
/*modl*/
.modl_window {
  width: calc(100% - 24px);
  max-width: 1366px;
  margin: 0 auto;
  padding: 40px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  overflow: auto;
  max-height: calc(100% - 30px); }
  .modl_window-wrap {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999; }
  .modl_window-overlay {
    background: #8dc63f;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .modl_window-cls {
    background: none;
    border: 0;
    padding: 0;
    margin-bottom: 33px; }
  .modl_window-flex {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }
  .modl_window-in {
    max-width: 940px;
    margin: 0 auto; }
  .modl_window-ttl {
    font-weight: bold;
    font-size: 42px;
    line-height: 51px; }
  .modl_window-txt {
    margin-top: 22px;
    font-size: 18px;
    line-height: 150%; }
  .modl_window-img {
    margin-top: 53px;
    text-align: center; }

/*modl*/
/*header*/
@media (min-width: 1200px) {
  .h-btn_menu {
    display: none; }
  .h-mob {
    display: none !important; } }

@media (min-width: 1200px) and (max-width: 1399px) {
  .h-menu ul li {
    padding: 14px 10px; }
    .h-menu ul li a {
      font-size: 14px; } }

@media (max-width: 1199px) {
  .h-menu {
    display: none; }
    .h-menu ul li a {
      font-size: 28px;
      line-height: 34px; }
  .h-logo {
    max-width: none;
    flex: 1; }
  .h-mob {
    padding-bottom: 26px; }
    .h-mob .h-btn {
      display: none; }
    .h-mob .h-menu {
      display: block; }
      .h-mob .h-menu ul {
        flex-direction: column;
        align-items: flex-end; } }

@media (max-width: 639px) {
  .h-menu ul li a {
    font-size: 21px;
    line-height: 25px; }
  .h-btn {
    display: none; }
  .h-mob .h-btn {
    display: block;
    text-align: right; } }

/*header*/
/*first*/
@media (max-width: 1199px) {
  .first-ttl {
    font-size: 48px;
    line-height: 100%; }
  .first-txt {
    font-size: 28px;
    line-height: 130%; } }

@media (max-width: 959px) {
  .first-wrap {
    min-height: 411px; }
  .first-ttl {
    font-size: 42px; }
  .first-txt {
    font-size: 21px; } }

@media (max-width: 639px) {
  .first-wrap {
    padding: 16px 5px 20px; }
  .first-ttl {
    font-size: 28px; }
  .first-txt {
    font-size: 18px; }
  .first-wrap {
    min-height: 220px; } }

/*first*/
/*numbers*/
@media (max-width: 1199px) {
  .numbers-bl-ttl {
    font-size: 48px;
    line-height: 100%; }
  .numbers-bl-txt {
    font-size: 28px;
    line-height: 34px; } }

@media (max-width: 959px) {
  .numbers-col {
    max-width: 100%; }
  .numbers-bl-ttl {
    font-size: 42px; }
  .numbers-bl-txt {
    font-size: 21px; } }

@media (max-width: 639px) {
  .numbers-bl-ttl {
    font-size: 28px; }
  .numbers-bl-txt {
    font-size: 18px;
    line-height: 130%; } }

/*numbers*/
/*cats*/
@media (max-width: 1199px) {
  .cats-col {
    max-width: 100%; }
  .cats-bl {
    max-width: 874px;
    margin: 0 auto; }
    .cats-bl-ttl {
      font-size: 42px;
      line-height: 51px; } }

@media (max-width: 959px) {
  .cats-bl {
    padding: 21px 30px 30px; }
    .cats-bl-ttl {
      font-size: 36px;
      line-height: 44px; }
    .cats-bl-txt {
      font-size: 18px; } }

@media (max-width: 639px) {
  .cats-bl {
    padding: 13px 20px 20px; }
    .cats-bl-ttl {
      font-size: 28px;
      line-height: 34px; }
    .cats-bl-btm {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start; }
    .cats-bl-more {
      margin-bottom: 20px; }
    .cats-bl-back, .cats-bl-front {
      background-position: center center; }
    .cats-bl:hover .cats-bl-front {
      transform: rotateY(0deg); }
    .cats-bl:hover .cats-bl-back {
      transform: rotateY(180deg); }
    .cats-bl.reverse .cats-bl-front {
      transform: rotateY(180deg); }
    .cats-bl.reverse .cats-bl-back {
      transform: rotateY(360deg); } }

/*cats*/
/*prod*/
@media (max-width: 1199px) {
  .prod-col {
    max-width: 50%; } }

@media (max-width: 959px) {
  .prod-wrap {
    padding-left: 15px;
    padding-right: 15px; }
  .prod-col {
    max-width: 100%; } }

@media (max-width: 639px) {
  .prod-bl {
    padding: 20px; }
    .prod-bl-ttl {
      font-size: 24px; }
    .prod-bl-txt {
      height: 47px; }
    .prod-bl-btm {
      flex-direction: column;
      margin-top: 20px; }
    .prod-bl-more {
      margin-bottom: 20px; }
    .prod-bl:hover .prod-bl-front {
      transform: rotateY(0deg); }
    .prod-bl:hover .prod-bl-back {
      transform: rotateY(180deg); }
    .prod-bl.reverse .prod-bl-front {
      transform: rotateY(180deg); }
    .prod-bl.reverse .prod-bl-back {
      transform: rotateY(360deg); } }

/*prod*/
/*abil*/
@media (max-width: 1199px) {
  .abil-txt {
    font-size: 18px;
    line-height: 22px; } }

@media (max-width: 959px) {
  .abil-col {
    max-width: 100%; } }

/*abil*/
/*coop*/
@media (max-width: 1199px) {
  .coop-col {
    max-width: 50%; }
  .coop-info-ttl {
    font-size: 36px;
    line-height: 100%; }
  .coop-info-txt {
    font-size: 18px;
    line-height: 130%; } }

@media (max-width: 959px) {
  .coop-ttl {
    text-align: left; }
  .coop-col {
    max-width: 100%; }
  .coop-wrap {
    padding-left: 15px;
    padding-right: 15px; }
  .coop-info-slider {
    max-width: 424px; } }

@media (max-width: 639px) {
  .coop-ttl {
    margin-bottom: 28px; }
  .coop-bl-ttl {
    font-size: 24px;
    line-height: 29px; }
  .coop-wrap {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 165px; }
  .coop-col {
    margin-bottom: 40px; }
  .coop-info {
    margin: 0 -20px; }
    .coop-info-txt {
      margin-bottom: 45px; }
    .coop-info-slider {
      padding-top: 60px; } }

/*coop*/
/*saving*/
@media (max-width: 959px) {
  .saving-txt {
    font-size: 21px; } }

@media (max-width: 639px) {
  .saving-wrap {
    padding: 17px 5px; }
  .saving-txt {
    margin-bottom: 28px; }
  .saving-row {
    width: 100%;
    align-items: center;
    flex-direction: column; }
  .saving-bl {
    padding-right: 0; } }

/*saving*/
/*qual*/
@media (max-width: 959px) {
  .qual .row {
    flex-direction: column;
    align-items: center; }
  .qual-col {
    max-width: 100%; } }

@media (max-width: 639px) {
  .qual-wrap {
    padding: 28px 17px; }
  .qual-ttl {
    text-align: left; }
  .qual-txt {
    text-align: left;
    margin-bottom: 37px; }
  .qual-bl-img {
    text-align: left; }
  .qual-bl-ttl {
    text-align: left; }
  .qual-col {
    margin-bottom: 35px; } }

/*qual*/
/*revs*/
@media (max-width: 959px) {
  .revs-slider {
    max-width: 448px; }
  .revs-slide-ttl {
    font-size: 24px;
    line-height: 115%; }
  .revs-slide-txt {
    font-size: 13px;
    line-height: 130%; } }

@media (max-width: 639px) {
  .revs {
    padding-bottom: 34px; }
    .revs-slider {
      padding: 60px 15px 10px; }
      .revs-slider .slick-arrow.arrow-prev {
        left: 15px; }
      .revs-slider .slick-arrow.arrow-next {
        right: 15px; }
    .revs-slide-ttl {
      font-size: 18px;
      text-align: left; }
    .revs-slide-txt {
      text-align: left; } }

/*revs*/
/*cf*/
@media (max-width: 959px) {
  .cf-wrap {
    padding-left: 15px;
    padding-right: 15px; }
  .cf-ttl {
    text-align: left; }
  .cf-txt {
    text-align: left; }
  .cf-txt {
    font-size: 21px; } }

@media (max-width: 959px) {
  .cf-wrap {
    padding-top: 28px; }
  .cf-txt {
    margin-bottom: 30px;
    font-size: 18px; } }

/*cf*/
/*footer*/
@media (max-width: 1199px) {
  .f-row {
    flex-direction: column-reverse; }
  .f-col {
    width: 100%; } }

@media (max-width: 639px) {
  .f-col {
    padding: 26px 30px 30px; } }

/*footer*/
/*modl*/
@media (max-width: 1199px) {
  .modl_window-ttl {
    font-size: 36px;
    line-height: 44px; } }

@media (max-width: 959px) {
  .modl_window-ttl {
    font-size: 36px;
    line-height: 44px; } }

@media (max-width: 639px) {
  .modl_window {
    padding: 20px; }
    .modl_window-cls {
      margin-bottom: 21px; }
    .modl_window-ttl {
      font-size: 28px;
      line-height: 100%; }
    .modl_window-txt {
      font-size: 16px;
      line-height: 150%;
      margin-top: 17px; }
    .modl_window-img {
      margin-top: 24px; } }

/*modl*/
