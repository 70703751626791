// Fonts
$f_Inter: 'Inter', sans-serif;

/*Inter-Regular*/
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular/Inter-Regular.eot'),
    url('../fonts/Inter-Regular/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Inter-Regular/Inter-Regular.svg'),
    url('../fonts/Inter-Regular/Inter-Regular.woff') format('woff'),
    url('../fonts/Inter-Regular/Inter-Regular.ttf') format('truetype') {}
    font-weight: normal;
    font-style: normal; }
/*Inter-Regular*/

/*Inter-Medium*/
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium/Inter-Medium.eot'),
    url('../fonts/Inter-Medium/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Inter-Medium/Inter-Medium.svg'),
    url('../fonts/Inter-Medium/Inter-Medium.woff') format('woff'),
    url('../fonts/Inter-Medium/Inter-Medium.ttf') format('truetype') {}
    font-weight: 500;
    font-style: normal; }
/*Inter-Medium*/

/*Inter-Bold*/
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold/Inter-Bold.eot'),
    url('../fonts/Inter-Bold/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Inter-Bold/Inter-Bold.svg'),
    url('../fonts/Inter-Bold/Inter-Bold.woff') format('woff'),
    url('../fonts/Inter-Bold/Inter-Bold.ttf') format('truetype') {}
    font-weight: 700;
    font-style: normal; }
/*Inter-Bold*/

/*Inter-SemiBold*/
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold/Inter-SemiBold.eot'),
    url('../fonts/Inter-SemiBold/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Inter-SemiBold/Inter-SemiBold.svg'),
    url('../fonts/Inter-SemiBold/Inter-SemiBold.woff') format('woff'),
    url('../fonts/Inter-SemiBold/Inter-SemiBold.ttf') format('truetype') {}
    font-weight: 600;
    font-style: normal; }
/*Inter-SemiBold*/

/*Inter-Light*/
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Light/Inter-Light.eot'),
    url('../fonts/Inter-Light/Inter-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Inter-Light/Inter-Light.svg'),
    url('../fonts/Inter-Light/Inter-Light.woff') format('woff'),
    url('../fonts/Inter-Light/Inter-Light.ttf') format('truetype') {}
    font-weight: 300;
    font-style: normal; }
/*Inter-Light*/
